import styled from "styled-components";
import { Helmet } from "react-helmet";
import metrics from "../../utils/metrics";
import { useSelector } from "react-redux";
import cartSelectors from "../../store/cart/selectors";

const TruemedLearnMore = styled.button`
  text-decoration: underline;
  color: var(--Solid-Primary-IndigoBlue-60);
`;

const TruemedPopupButton = ({ label = "Learn more" }: { label?: string }) => {
  const activeCart = useSelector(cartSelectors.activeCart);

  const handleClick = () => {
    metrics.track("Truemed PDP Element Clicked", { cart_id: activeCart?.id });
    window?.Truemed?.showModal();
  };

  return (
    <>
      <Helmet>
        <script
          defer
          src="https://static.truemed.com/widgets/ritual-pdp-widget.min.js"
        ></script>
      </Helmet>
      <TruemedLearnMore
        role="button"
        className="typography-body3 font-circular"
        onClick={handleClick}
        aria-label="Learn more about Truemed HSA/FSA payments"
      >
        {label}
      </TruemedLearnMore>
    </>
  );
};

export default TruemedPopupButton;
