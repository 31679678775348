import { createRef, Component } from "react";
import styled from "styled-components";

// Utils
import { v4 } from "uuid";
import { Icons } from "../../utils/react-svg";
import { media, Opacity } from "../../utils/style";
import metrics from "../../utils/metrics";

// Components
import MagicVideo from "../MagicVideo";

// Styled Elements
const OverlayContainer = styled.div`
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const OverlayContent = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})`
  z-index: 1001;
  position: fixed;
  top: 120px;
  bottom: 120px;
  left: 135px;
  right: 135px;

  ${media.mobile`
    top: 80px;
    left: 24px;
    bottom: 80px;
    right: 24px;
  `};
`;

const OverlayBackground = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: default;
  backdrop-filter: blur(10px);
`;

const CloseButton = styled.button`
  font-weight: 400;
  position: absolute;
  color: #142b6f;
  width: 44px;
  height: 44px;
  right: 90px;
  top: 60px;
  opacity: 1;
  float: right;
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  background: transparent;
  border: none;
  outline: none !important;
  z-index: 1001;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light}s;
  }

  ${media.mobile`
    top: 10px;
    right: 25px;
  `};
`;

export default class VideoOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      session_id: null,
      sendSeekStarted: true,
      sendSeekCompleted: true,
    };

    this.video = createRef();
  }

  componentDidMount() {
    this.setState({
      session_id: v4(),
    });
  }

  isPhone() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  }

  play() {
    if (!this.video) return;

    if (this.isPhone()) {
      this.video.webkitEnterFullscreen();
    }
    this.video.current.play();
  }

  stop() {
    if (!this.video) return;

    this.video.current.pause();
  }

  _trackVideoEvent(eventName, currentTime, duration, volume) {
    const { articleVideo } = this.props;

    metrics.track(eventName, {
      session_id: this.state.session_id,
      content_asset_id: articleVideo.id,
      content_asset_title: articleVideo.title,
      content_asset_description: articleVideo.description,
      content_pod_id: articleVideo.id,
      position: Math.floor(currentTime),
      total_length: Math.floor(duration),
      // bitrate: 55,
      // framerate: 29,
      video_player: "html5",
      sound: Math.floor(volume * 100),
      // full_screen: false,
      ad_enabled: false,
      quality: "hd1080",
      livestream: false,
    });
  }

  onPause(e) {
    let { currentTime, duration, volume } = e.target;
    this._trackVideoEvent(
      "Video Playback Paused",
      currentTime,
      duration,
      volume,
    );
  }

  onPlay(e) {
    let { currentTime, duration, volume } = e.target;
    if (currentTime === 0) {
      this._trackVideoEvent(
        "Video Playback Started",
        currentTime,
        duration,
        volume,
      );
    } else {
      this._trackVideoEvent(
        "Video Playback Resumed",
        currentTime,
        duration,
        volume,
      );
    }
  }

  onEnded(e) {
    const { currentTime, duration, volume } = e.target;

    this._trackVideoEvent(
      "Video Playback Completed",
      currentTime,
      duration,
      volume,
    );
  }

  onSeeking(e) {
    if (this.sendSeekStartedTimeout) {
      clearTimeout(this.sendSeekStartedTimeout);
    }

    if (this.state.sendSeekStarted) {
      let { currentTime, duration, volume } = e.target;
      this._trackVideoEvent(
        "Video Playback Seek Started",
        currentTime,
        duration,
        volume,
      );
      this.setState({
        sendSeekStarted: false,
      });
    }

    this.sendSeekStartedTimeout = setTimeout(() => {
      this.setState({
        sendSeekStarted: true,
      });
    }, 500);
  }

  onSeeked(e) {
    if (this.sendSeekCompletedTimeout) {
      clearTimeout(this.sendSeekCompletedTimeout);
    }

    const { currentTime, duration, volume } = e.target;

    this.sendSeekCompletedTimeout = setTimeout(() => {
      this._trackVideoEvent(
        "Video Playback Seek Completed",
        currentTime,
        duration,
        volume,
      );
    }, 500);
  }

  render() {
    const { articleVideo, onClick, visible } = this.props;

    return (
      <OverlayContainer style={{ display: visible ? "block" : "none" }}>
        <OverlayContent>
          <MagicVideo
            {...articleVideo}
            onPause={this.onPause.bind(this)}
            onEnded={this.onEnded.bind(this)}
            onPlay={this.onPlay.bind(this)}
            onSeeking={this.onSeeking.bind(this)}
            onSeeked={this.onSeeked.bind(this)}
            ref={this.video}
          />
        </OverlayContent>
        <CloseButton type="button" aria-label="Close" onClick={onClick}>
          <Icons.Close />
        </CloseButton>
        <OverlayBackground onClick={onClick} />
      </OverlayContainer>
    );
  }
}
