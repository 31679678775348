import { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Components
import Carousel from "../Carousel";
import Container from "../Container";
import Row from "../Row";
import Text from "../Text";
import IngredientCard from "./IngredientCard";
import IngredientsOverviewSlide from "./IngredientsOverviewSlide";

// Utils
import metrics from "../../utils/metrics";
import { ProductSKU } from "../../utils/product";
import { Icons } from "../../utils/react-svg";
import { Color, Font, Opacity, rem, responsive } from "../../utils/style";
import MagicLink from "../MagicLink";
import { ContentfulProduct } from "../../utils/productTypes";

// Styled Elements
const Wrapper = styled(Container).attrs({
  role: "section",
  "aria-labelledby": "ingredients-overview-header",
})`
  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `};
`;

const HeaderColumn = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 56px;
  `};
`;

const Header = styled.h2.attrs({
  id: "ingredients-overview-header",
})`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 500;
  letter-spacing: -0.4px;
  text-align: center !important;
  color: ${Color.ritualBlue};
  margin: 0 auto 16px;

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1px;
    margin: 0 0 24px;
  `}
`;

const Subhead = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  color: ${Color.ritualBlue};
  margin: 0;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}

  span {
    font: inherit;
  }
`;

const ArticleLinkColumn = styled.div`
  text-align: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${responsive.md`
    margin-top: 56px;
  `};
`;

const ArticleLinkHeader = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 500;
  text-align: center !important;
  margin: 0;
  padding: 0;

  ${responsive.md`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    max-width: 454px;
  `};
`;

const SupplementLink = styled.button`
  background: none;
  border: none;
  font-size: ${rem(16)};
  line-height: ${rem(16)};
  font-weight: normal;
  color: ${Color.ritualBlue};
  padding: unset;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(18)};
  `}

  border-bottom: 1px solid ${Color.ritualBlue};
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const ExpandIngredients = styled.div`
  border-top: 1px solid ${Color.fadedGrey};
  margin-top: 8px;
  padding-top: 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${responsive.sm`
    margin-top: 0;
  `};

  ${responsive.md`
    margin-top: 16px;
  `};

  button {
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;

    span {
      ${Font.circular}
      color: ${Color.ritualBlue};
      font-size: ${rem(14)};
      line-height: ${rem(24)};
      font-weight: 500;
      margin: 0 8px 0 0;
    }

    svg {
      width: 14px;
      height: 8px;
    }
  }
`;

const ArticleLink = styled(MagicLink)`
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  display: inline-block;
  border-bottom: 2px solid ${Color.ritualBlue};
  margin: 24px 0 0;
`;

type IngredientsOverviewProps = {
  key: string;
  openFlyout: (menu: string) => void;
  product: ContentfulProduct;
  selectedPlanId: string;
  ingredients: any; //ingredint[]
  ingredientsSubheader: {
    childMarkdownRemark: {
      rawMarkdownBody: string;
    };
  };
  somethingMissingTitle: string;
};

type DeviceState = "small" | "large";

const IngredientsOverview = (props: IngredientsOverviewProps) => {
  const {
    ingredients,
    product,
    ingredientsSubheader,
    somethingMissingTitle,
    openFlyout,
    selectedPlanId,
  } = props;
  const cardLimits = {
    small: 4,
    large: 6,
  };
  const [expandedView, setExpandedView] = useState(false);
  const [deviceState, setDeviceState] = useState<DeviceState>("large");
  const [carouselControls, setCarouselControls] = useState(true);
  const [activeSlideIndex, setActiveSlideIndex] = useState(-1);

  const resizeHandler = useCallback(() => {
    const width = window.innerWidth;
    const newDeviceState = width >= 960 ? "large" : "small";

    if (newDeviceState !== deviceState) {
      setDeviceState(newDeviceState);
      setCarouselControls(newDeviceState === "large");
    }
  }, [deviceState]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  const handlePopup = () => {
    return openFlyout("Supplement Facts");
  };

  const toggleView = () => {
    setExpandedView(!expandedView);

    if (deviceState === "small" && !expandedView) {
      const element = document.getElementById(
        `ingredient-card-${cardLimits["small"] - 1}`,
      );
      const newScrollPosition =
        element &&
        element.getBoundingClientRect().top +
          document.documentElement.scrollTop;

      window && window.scrollTo(0, newScrollPosition || 0);
    }
  };

  const hideCarousel = () => {
    setActiveSlideIndex(-1);
    document.body.classList.remove("no-scroll");
  };

  const openCarousel = (index: number) => {
    setActiveSlideIndex(index);
    document.body.classList.add("no-scroll");
  };

  const clickEvent = (ingredient: { name: string }) => {
    metrics.track("Ingredient Card Clicked", {
      name: ingredient.name,
      location: "Ingredient List",
    });
  };

  const generateCarouselSlides = () => {
    return ingredients.map((ingredient: any, index: number) => {
      return (
        <IngredientsOverviewSlide
          key={index}
          ingredient={ingredient}
          id={`ingredient-card_${index}`}
          product={product}
          selectedPlanId={selectedPlanId}
          isMobile={deviceState === "small"}
        />
      );
    });
  };

  const visibleCards = expandedView
    ? ingredients.length
    : cardLimits[deviceState];

  const caretType = expandedView ? "CaretUpRounded" : "CaretDownRounded";
  const CaretDown = Icons[caretType];

  const isPrenatalMulti = [ProductSKU.EPRE60, ProductSKU.EPOST60].includes(product?.sku);
  const articleLinkTo = isPrenatalMulti
    ? "/articles/folic-acid-for-pregnancy"
    : "/articles/what-vitamins-should-i-take";
  const epreArticleLinkHeader = intl.t(
    "product.ingredients.header-folate",
    "We opted for methylated folate as 5-MTHF - the active form of folic acid to support folate adequacy.*",
  );

  return (
    <Wrapper>
      <Row>
        <HeaderColumn>
          <Header>
            <Text
              id="product.ingredients.header"
              defaultMessage="{n} Traceable Ingredients"
              values={{ n: ingredients.length }}
            />
          </Header>
          <Subhead>
            {ingredientsSubheader.childMarkdownRemark.rawMarkdownBody}{" "}
            <SupplementLink
              aria-haspopup="true"
              aria-controls="supplement-facts-popup"
              onClick={handlePopup}
            >
              <Text
                id="product.ingredients.view-facts"
                defaultMessage="View Supplement Facts"
              />
            </SupplementLink>
          </Subhead>
        </HeaderColumn>
      </Row>

      <Row>
        {ingredients
          .slice(0, visibleCards)
          .map((ingredient: any, i: number) => {
            return (
              <IngredientCard
                id={`ingredient-card_${i}`}
                index={i}
                ingredient={ingredient}
                product={product}
                key={"ingredient-" + i}
                handleClick={() => {
                  openCarousel(i);
                  clickEvent(ingredient);
                }}
                activeSlideIndex={activeSlideIndex}
              />
            );
          })}
      </Row>

      <Row>
        <ExpandIngredients className="col-12">
          <button onClick={toggleView}>
            <span>
              {expandedView ? (
                <Text
                  id="product.ingredients.see-less"
                  defaultMessage="See Less"
                />
              ) : (
                <Text
                  id="product.ingredients.see-all"
                  defaultMessage="See All {n}"
                  values={{ n: ingredients.length }}
                />
              )}
            </span>
            <CaretDown />
          </button>
        </ExpandIngredients>
      </Row>

      <Row>
        <ArticleLinkColumn className="col-12 col-sm-8 offset-sm-2">
          <ArticleLinkHeader>
            {isPrenatalMulti ? epreArticleLinkHeader : somethingMissingTitle}
          </ArticleLinkHeader>
          <ArticleLink to={articleLinkTo} state={{ productSku: product.sku }}>
            {isPrenatalMulti ? (
              <Text
                id="product.ingredients.link-folate"
                defaultMessage="Read More About Folate"
              />
            ) : (
              <Text
                id="product.ingredients.link-how-much"
                defaultMessage="Read Why Less Is More"
              />
            )}
          </ArticleLink>
        </ArticleLinkColumn>
      </Row>

      {activeSlideIndex >= 0 && (
        <Carousel
          activeSlideIndex={activeSlideIndex}
          slides={generateCarouselSlides()}
          close={hideCarousel}
          controls={carouselControls}
          repeat={true}
        />
      )}
    </Wrapper>
  );
};

export default IngredientsOverview;
